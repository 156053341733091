<template>
  <div>
    <CRow>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <CCard class="col-xl-12 padding-0">
        <CCardHeader>
            <CButton color="primary" class="btn-block col-xl-2" @click="addData()"><v-icon name="pencil-alt"/> Add Air Base</CButton>
        </CCardHeader>
        <CCardBody class="" style="text-align: center;">
            <CSpinner
                v-if="isLoading"
                color="info"
                style="width:3rem;height:3rem;"
            />
            <span v-if="!isLoading && this.airports.length < 1">Data Belum Ada</span>
            <CDataTable
                v-if="this.airports.length > 0"
                :hover="true"
                :striped="true"
                :border="true"
                :small="true"
                :fixed="true"
                :sorter="true"
                :items="this.airports"
                :fields="fields"
                style="text-align: center;"
                tableFilter
                itemsPerPageSelect
                :itemsPerPage="this.itemperpage"
                :pagination="{ doubleArrows: true, align: 'end'}"
            >
            <template v-slot:number="data">
                <td>
                    {{data.index+1}}
                </td>
            </template>
            <template v-slot:actions="data">
                <td>
                  <CDropdown
                    color="secondary"
                    placement="bottom"
                  >
                    <template #toggler>
                      <CButton :color="data.item.is_active?'secondary':'danger'" class="btn-sm">
                        <v-icon name="caret-down"/>
                      </CButton>
                    </template>
                    <CDropdownItem @click="viewData(data.item.uuid)">
                      <v-icon name="eye" class="mr-1"/>View
                    </CDropdownItem>
                    <CDropdownItem @click="editData(data.item.uuid)">
                      <v-icon name="pencil-alt" class="mr-1 v-mid"/> Edit
                    </CDropdownItem>
                    <CDropdownItem @click="removeData(data.item.uuid)">
                      <v-icon name="trash" class="mr-1 v-mid"/> Delete
                    </CDropdownItem>
                  </CDropdown>
                </td>
            </template>
            </CDataTable>
        </CCardBody>
      </CCard>
    </CRow>
    <CModal
      title="Modal Delete"
      :show.sync="modalDelete"
      size="sm"
    >
      <span>Are you sure remove this data ?</span>
      <template #header>
        <h6 class="modal-title">Remove Data</h6>
        <CButtonClose @click="modalDelete = false"/>
      </template>
      <template #footer>
        <CButton @click="modalDelete = false" color="light" class="text-left">Cancel</CButton>
        <CButton @click="destroyData(delId)" color="danger">Yes</CButton>
      </template>
    </CModal>
    <CModal
      title="Modal Form"
      :show.sync="modalForm"
      :closeOnBackdrop="false"
      size="xl"
    >
      <CCol v-if="isMLoading" xl="12" style="text-align: center;">
        <CSpinner
          color="info"
        />
      </CCol>
      <CForm v-if="!isMLoading">
        <CInput
          label="ICAO Code"
          :description="errors.code"
          placeholder="Please fill air base ICAO code"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.code"
        />
        <CInput
          label="Name"
          :description="errors.name"
          placeholder="Please fill air base name"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.name"
        />
        <CInput
          label="Runway Size"
          :description="errors.runway_size"
          placeholder="Please fill runway size"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.runway_size"
        />
        <CInput
          label="Taxiway Size"
          :description="errors.taxiway_size"
          placeholder="Please fill taxiway size"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.taxiway_size"
        />
        <CInput
          label="Apron Area"
          :description="errors.apron_area"
          placeholder="Please fill apron area"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.apron_area"
        />
        <CInput
          label="Latitude"
          :description="errors.latitude"
          placeholder="Please fill latitude"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.latitude"
        />
        <CInput
          label="Longitude"
          :description="errors.longitude"
          placeholder="Please fill longitude"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.longitude"
        />
        <CInput
          label="Elevation"
          :description="errors.elevation"
          placeholder="Please fill elevation"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.elevation"
        />
        <div class="form-group" role="group">
          <label>Runway</label>
          <CRow>
            <CCol xl="6">
              <input 
                v-model="runwayfirst" 
                type="text" 
                placeholder="Please fill runway"
                :readonly="frmaction=='view'?true:false"
                class="form-control">
            </CCol>
            <CCol xl="6">
              <input 
                v-model="runwaylast" 
                type="text" 
                placeholder="Please fill runway"
                :readonly="frmaction=='view'?true:false"
                class="form-control">
            </CCol>
          </CRow>
          <small class="form-text text-muted w-100">{{errors.runway}}</small>
        </div>
        <div class="form-group" role="group">
          <label>Timezone</label>
          <v-select 
              :reduce="timezones => timezones.value"
              v-model="form.timezone"
              :options="timezones"
              placeholder="Please select timezone"
              :disabled="frmaction=='view'?true:false"
          />
          <small class="form-text text-muted w-100">{{errors.timezone}}</small>
        </div>
        <CInput
          label="IATA Code"
          :description="errors.iata_code"
          placeholder="Please fill iata code"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.iata_code"
        />
        <CInput
          label="Location"
          :description="errors.location"
          placeholder="Please fill location"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.location"
        />
        <CInput
          label="Geographic Latitude"
          :description="errors.geo_lat"
          placeholder="Please fill geographic latitude"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.geo_lat"
        />
        <CInput
          label="Geographic Longitude"
          :description="errors.geo_lng"
          placeholder="Please fill geographic longitude"
          type="text"
          vertical
          :readonly="frmaction=='view'?true:false"
          v-model="form.geo_lng"
        />
        <div class="form-group" role="group" v-if="frmId && !actived">
          <label>Activate</label>
          <v-select 
              :reduce="activates => activates.value"
              v-model="form.is_active"
              :options="activates"
              placeholder="Please select activate"
              :disabled="frmaction=='view'?true:false"
          />
          <small class="form-text text-muted w-100">{{errors.is_active}}</small>
        </div>
      </CForm>
      <template #header>
        <h6 class="modal-title">{{frmId==''?'Add':frmaction!='view'?'Edit':'Show'}} Data</h6>
        <CButtonClose @click="modalForm = false"/>
      </template>
      <template #footer>
        <CButton @click="modalForm = false" color="light" class="text-left">Cancel</CButton>
        <CButton v-if="frmaction=='view'?false:true" @click="frmaction=='add'?postData():updateData(frmId)" color="info">{{frmId==''?'Save':'Update'}}</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Airport',
  components: {
  },
  props: {
      fields: {
        type: Array,
        default () {
            return [
                { key: 'number', label: '#' },
                { key: 'code', label: 'Code' },
                { key: 'name', label: 'Name' },
                { key: 'actions', label: '' }
            ]
        }
      },
  },
  data () {
    return {
        isLoading: false, isMLoading: false,
        modalForm: false, modalDelete: false,
        itemperpage: 10,
        airports: [],
        timezones: [
          { value: 'WIB', label: 'WIB (Waktu Indonesia Barat)' },
          { value: 'WITA', label: 'WITA (Waktu Indonesia Tengah)' },
          { value: 'WIT', label: 'WIT (Waktu Indonesia Timur)' },
        ],
        activates: [
          { value: 1, label: 'Active' },
          { value: 0, label: 'Disactive' }
        ],
        delId: '', frmId: '', frmaction: 'view',
        runwayfirst: '', runwaylast: '', actived: '',
        form: {
          uuid: '',
          code: '',
          name: '',
          runway_size: '',
          taxiway_size: '',
          apron_area: '',
          latitude: '',
          longitude: '',
          elevation: '',
          runway: '',
          runway_modified: ['', ''],
          timezone: '',
          iata_code: '',
          location: '',
          geo_lat: '',
          geo_lng: '',
          is_active: ''
        },
        errors: {
          code: '',
          name: '',
          runway_size: '',
          taxiway_size: '',
          apron_area: '',
          latitude: '',
          longitude: '',
          elevation: '',
          runway: '',
          timezone: '',
          iata_code: '',
          location: '',
          geo_lat: '',
          geo_lng: '',
          is_active: ''
        }
    }
  },
  watch: {
    modalForm: {
      handler: function (val, oldVal) {
        if(!val){
          this.frmId = '', this.frmaction = 'view', 
          this.runwayfirst = '', this.runwaylast = '', this.actived = '',
          this.form = { 
            uuid: '', 
            code: '', 
            name: '',
            runway_size: '',
            taxiway_size: '',
            apron_area: '',
            latitude: '',
            longitude: '',
            elevation: '',
            runway: '',
            runway_modified: ['', ''],
            timezone: '',
            iata_code: '',
            location: '',
            geo_lat: '',
            geo_lng: '',
            is_active: ''
          }
          this.errors = { 
            code: '', 
            name: '',
            runway_size: '',
            taxiway_size: '',
            apron_area: '',
            latitude: '',
            longitude: '',
            elevation: '',
            runway: '',
            timezone: '',
            iata_code: '',
            location: '',
            geo_lat: '',
            geo_lng: '',
            is_active: ''
          }
        }
      }
    },
    modalDelete: {
      handler: function (val, oldVal) {
        if(!val){ this.delId = '' }
      }
    }
  },
  created() {
    this.reRender()
  },
  methods: {
    ...mapActions('airport', ['indexAPT', 'storeAPT', 'updateAPT', 'showAPT', 'destroyAPT']),
    reRender(){
      this.isLoading = true
      this.airports = []
      this.indexAPT().then((response) => {
        this.airports = response.length > 0 ? response : []
        this.isLoading = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    viewData(uuid){
      this.frmaction = 'view'
      this.frmId = uuid
      this.modalForm = true
      this.showData(uuid)
    },
    addData(){
      this.frmaction = 'add'
      this.modalForm = true
    },
    editData(uuid){
      this.frmaction = 'edit'
      this.frmId = uuid
      this.modalForm = true
      this.showData(uuid)
    },
    removeData(uuid){
      this.modalDelete = true
      this.delId = uuid
    },
    showData(uuid){
      this.isMLoading = true
      this.showAPT(uuid).then((response) => {
        this.form = response
        this.actived = response.is_active
        this.runwayfirst = response.runway_modified?response.runway_modified[0]:''
        this.runwaylast = response.runway_modified?response.runway_modified[1]:''
        this.isMLoading = false
      }).catch((error) => {
        this.isMLoading = false
      })
    },
    postData(){
      this.isMLoading = true
      this.form.runway = this.runwayfirst+';'+this.runwaylast
      this.storeAPT(this.form).then((response) => {
        this.reRender()
        this.isMLoading = false
        this.modalForm = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isMLoading = false
      })
    },
    updateData(uuid){
      this.isMLoading = true
      this.form.runway = this.runwayfirst+';'+this.runwaylast
      this.updateAPT(this.form).then((response) => {
        this.reRender()
        this.isMLoading = false
        this.modalForm = false
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error.statusText })
        this.getError(error.data)
        this.isMLoading = false
      })
    },
    destroyData(uuid){
      this.modalDelete = false
      this.destroyAPT(uuid).then((response) => {
        this.reRender()
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isLoading = false
      })
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        this.errors.code = data.errors.code ? data.errors.code[0] : ''
        this.errors.name = data.errors.name ? data.errors.name[0] : ''
        this.errors.runway_size = data.errors.runway_size ? data.errors.runway_size[0] : ''
        this.errors.taxiway_size = data.errors.taxiway_size ? data.errors.taxiway_size[0] : ''
        this.errors.apron_area = data.errors.apron_area ? data.errors.apron_area[0] : ''
        this.errors.latitude = data.errors.latitude ? data.errors.latitude[0] : ''
        this.errors.longitude = data.errors.longitude ? data.errors.longitude[0] : ''
        this.errors.elevation = data.errors.elevation ? data.errors.elevation[0] : ''
        this.errors.runway = data.errors.runway ? data.errors.runway[0] : ''
        this.errors.timezone = data.errors.timezone ? data.errors.timezone[0] : ''
        this.errors.iata_code = data.errors.iata_code ? data.errors.iata_code[0] : ''
        this.errors.location = data.errors.location ? data.errors.location[0] : ''
        this.errors.geo_lat = data.errors.geo_lat ? data.errors.geo_lat[0] : ''
        this.errors.geo_lng = data.errors.geo_lng ? data.errors.geo_lng[0] : ''
        this.errors.is_active = data.errors.is_active ? data.errors.is_active[0] : ''
      }}
    }
  }
}
</script>